import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import useMediaQuery from "react-responsive";
import { useState } from "react";
import './mobileheader.css'

function MobileHeader() {
    const isMd = useMediaQuery({
        query: '(max-width: 800px)'
      })
  const [expand, setExpand] = useState(isMd ? false : "lg");

  return (
    <>
      <Navbar key={expand} bg="light" expand={expand} className="mb-3">
        <Container fluid>
          <Navbar.Brand href="https://hairstylechannel.com/">
            <img
              src="https://hairstylechannel.com/wp-content/themes/hsc/images/hsc.png"
              width="231"
              height="43"
              className="d-inline-block align-top"
              alt="Hairstyle channel"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
               
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="https://hairstylechannel.com/womens-haircuts/">Women's Hairstyles</Nav.Link>
                <Divider/>
                <Nav.Link href="https://hairstylechannel.com/mens-haircuts/">Men's Hairstyles</Nav.Link>
                <Divider/>
                
                <Nav.Link href="https://hairstylechannel.com/stylist/">Stylist</Nav.Link>
                  <Divider/>
                <Nav.Link href="https://hairstylechannel.com/gallery/">Gallery</Nav.Link>
                  <Divider/>
                <Nav.Link href="https://hairstylechannel.com/become-a-contributor/">Contribute</Nav.Link>
                  <Divider/>

                <Nav.Link style={{background:"#f1f1f1"}} href="https://hairstyleschannel.com">
                  Try On Hairstles
                </Nav.Link>
                  <Divider/>
                <Nav.Link href="https://hairstylechannel.com/contact/">
                  Contact Us
                </Nav.Link>
                  <Divider/>
              </Nav>
                <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop:"20px",
                    marginBottom:"10px",
                  }}
                  >
                  <Nav.Link
                    style={{
                      background: "black",
                      padding: "5px",
                      borderRadius: "10px",
                      marginRight:"10px"
                    }}
                    href="https://www.instagram.com/officalhairstylechannel/?hl=en"
                  >
                    <span>
                      <img
                        src="https://hairstylechannel.com/wp-content/uploads/2022/05/instagram-footer.png"
                        alt="instagram"
                      />
                    </span>
                  </Nav.Link>
                  <Nav.Link
                    style={{
                      background: "black",
                      padding: "5px",
                      borderRadius: "10px",
                    }}
                    href="https://www.facebook.com/AllHairstylesChannel/"
                  >
                    <span>
                      <img
                        src="https://hairstylechannel.com/wp-content/uploads/2022/05/facebook-footer.png"
                        alt="facebook"
                      />
                    </span>
                  </Nav.Link>
                </div>
                <Divider/>
              </>
              
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          </Container>
      </Navbar>
    </>
  );
}


function Divider(){
  return (
    <div style={{borderBottom:"1px solid #f1f1f1"}}>

    </div>
  )
}
export default MobileHeader;
