"use client";
import React, { useContext } from "react";
import styles from "./showcamera.module.css";
import {
  ArrowDownwardSharp,
  Camera,
  FlipToBack,
  KeyboardArrowDown,
} from "@mui/icons-material";
import Webcam from "react-webcam";
import { useNavigate } from 'react-router-dom';
import { Context } from "../../context/ContextApi";


function ShowCameraModule() {
  const navigate = useNavigate();
  const webcamRef = React.useRef(null);
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [devices, setDevices] = React.useState([]);
  const [currentDevice, setCurrentDevice] = React.useState(null);
  const [mirrorStatus, setMirrorStatus] = React.useState(false);
  const context = useContext(Context)

  const handleDevices = React.useCallback(
    (mediaDevices) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );
  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);
  return (
    <div className={styles.parentWrapper}>
      <div className={styles.header}>
        <button className={styles.backBtn} onClick={()=>{
          window.history.back()
        }}>&lt; Back</button>
        <button
          className={styles.showDevicesBtn}
          onClick={() => {
            setShowDropdown((prev) => !prev);
          }}
        >
          CHOOSE CAMERA <KeyboardArrowDown />
        </button>
      </div>

      {showDropdown
        ? devices.map((device, index) => {
            return (
              <button
                className={styles.deviceBtn}
                onClick={() => {
                  setCurrentDevice(device.deviceId);
                }}
              >{`${device.label} Device ${index + 1}`}</button>
            );
          })
        : null}
      <h6 className={` ${styles.note}`}>
        Note: If camera is not visible please tap screen once.
      </h6>
      <div className={styles.webcamDiv}>
        <Webcam
          audio={false}
          mirrored={mirrorStatus}
          videoConstraints={{
            aspectRatio: 1,
            deviceId: currentDevice ? currentDevice : "",
          }}
          ref={webcamRef}
          // width={640}
          height={646}
          screenshotFormat="image/jpeg"
          className={styles["camera-video-box"]}
        />
        <img src={`/Oval.png`} className={styles.faceShape}></img>
      </div>

      <div className={styles.shutterWrapper}>
        <div className={styles.makeWidthHalf}>
          <button
            className={styles.flipCamera}
            onClick={() => setMirrorStatus((prev) => !prev)}
          >
            <FlipToBack />
            <span className={` ${styles.smallText}`}>
              Mirror reverse
            </span>
            <span className={` ${styles.smallText}`}>
              camera
            </span>
          </button>
          <button
            className={styles.click}
            onClick={() => {
              const imageSrc = webcamRef.current.getScreenshot();
              context.setCameraPhoto(imageSrc)
              navigate("/edit-photo")
            }}
          >
            <Camera style={{ fontSize: "60px", fill: "black" }} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ShowCameraModule;
