import React from "react";
import styles from './takephoto.module.css'
import MainSidebar from "../../components/MainSidebar/MainSideBar";
import TakeAPhotoModule from "../../components/TakeAPhotoModule/TakeAPhotoModule";

function TakeAPhoto() {
  return (
    <div className={styles.parentWrapper}>
      <MainSidebar>
        <TakeAPhotoModule />
      </MainSidebar>
    </div>
  );
}

export default TakeAPhoto;
