"use client";
import React, { useContext } from "react";
import styles from "./mystylearrowbuttons.module.css";
import {
  ArrowBackIosNewSharp,
  ArrowForwardIosSharp,
} from "@mui/icons-material";
import { Context } from "../../context/ContextApi";
import Hairstyles from "../../helper/hairstyles.hairstylesdetails.json";
import Models from "../../helper/hairstyles.models.json";

function MyStyleArrowButtons({ HairImage, setShowAds, showAds }) {
  const context = useContext(Context);
  const handlePreviousClick = () => {
    if (showAds) {
      setShowAds(false);
      return;
    }
    const prevIndex =
      context.currentIndex === 0
        ? context.selectedPackage.hairstyles.length - 1
        : context.currentIndex - 1;

    if (
      context.currentIndex !== 0 &&
      context.currentIndex % 4 === 0 &&
      context.currentIndex > 0
    ) {
      setShowAds(true);
    }

    context.setCurrentIndex(prevIndex);
    updateHairstyleAndModel(prevIndex);
  };
  const handleNextClick = () => {
    console.log("context.currentIndex ",context.currentIndex )
    if (showAds) {
      setShowAds(false);
      return;
    }
    const nextIndex =
      (context.currentIndex + 1) % context.selectedPackage.hairstyles.length;
    if (
      context.currentIndex !== 0 &&
      context.currentIndex % 4 === 0 &&
      context.currentIndex < context.selectedPackage.hairstyles.length - 1
    ) {
      setShowAds(true);
    }
    updateHairstyleAndModel(nextIndex);
    context.setCurrentIndex(nextIndex);
    console.log(nextIndex);
  };

  const updateHairstyleAndModel = async (nextIndex) => {
    const currentHairstyle = context.selectedPackage.hairstyles[nextIndex];
    const newHairstyleDetail = Hairstyles.find(
      (item) => item._id.$oid === currentHairstyle.$oid
    );

    context.setHairstyleDetail(newHairstyleDetail);

    const newModelImg = Models.find(
      (model) =>
        model.category[0].$oid === newHairstyleDetail.category[0].$oid &&
        model.status === "active"
    );

    context.setModelImg(newModelImg);
    if (context.selectedColor) {
      try {
        const response = await fetch(
          `/${newHairstyleDetail?._id?.$oid}_grey.png`
        );
        const blob = await response.blob();

        const reader = new FileReader();
        
        reader.onload = () => {
          console.log(document.getElementById("hair"));
          document.getElementById("hair").removeAttribute("data-caman-id");
          window.Caman(`#${HairImage.current.id}`, function (err) {
            this.revert();
          });
          window.Caman(
            `#hair`,
            `/${newHairstyleDetail?._id?.$oid}_grey.png`,
            function (err) {
              this.revert();
              this.colorize(
                context.selectedColor.red,
                context.selectedColor.green,
                context.selectedColor.blue,
                context.selectedColor.strength
              );
              this.render(async function () {
                const picture = this.toBase64();
                context.setHairstyleColoredImage(picture);
              });
            }
          );
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    }
  };
  return (
    <div className={styles.buttonWrapper}>
      <button className={styles.commonBtnStyles} onClick={handlePreviousClick}>
        <ArrowBackIosNewSharp style={{ fontSize: "40px" }} />
        <span className={` ${styles.btnText}`}>Prev Hairstyle</span>
      </button>
      <button className={styles.commonBtnStyles} onClick={handleNextClick}>
        <ArrowForwardIosSharp style={{ fontSize: "40px" }} />
        <span className={` ${styles.btnText}`}>Next Hairstyle</span>
      </button>
    </div>
  );
}

export default MyStyleArrowButtons;
