import MainSidebar from '../../components/MainSidebar/MainSideBar'
import PackageModule from '../../components/PackagesModule/PackagesModule'
import styles from './packagespage.module.css'


export default function Packages() {
  return (
    <div className={styles.parentWrapper}>
      
      <MainSidebar >
        <PackageModule/>
      </MainSidebar>

    </div>
  )
}
