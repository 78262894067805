import React, { useContext } from "react";
import styles from "./mystyleactions.module.css";
import DownloadIcon from "@mui/icons-material/Download";
import InfoIcon from "@mui/icons-material/Info";
import FlipToBackIcon from "@mui/icons-material/FlipToBack";
import ImageIcon from "@mui/icons-material/Image";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { Context } from "../../context/ContextApi";

import html2canvas from "html2canvas";
function MyStyleActions() {
  const context = useContext(Context);
  const actions = [
    {
      actionName: "Hairstyle Info",
      actionIcon: InfoIcon,
      action: () => {
        context.setHairstyleInfo((prev) => !prev);
      },
    },
    {
      actionName: "Change Hair Color",
      actionIcon: ColorLensIcon,
      action: () => {
        context.setColorSlider((prev) => !prev);
      },
    },
    {
      actionName: "Remove Background",
      actionIcon: ImageIcon,
      action: () => {
        context.setRemoveBackground((prev) => !prev);
      },
    },
    {
      actionName: "Flip Hairstyle",
      actionIcon: FlipToBackIcon,
      action: () => {
        context.setFlip((prev) => !prev);
      },
    },
    {
      actionName: "Save Photo",
      actionIcon: DownloadIcon,
      action: async () => {
        const contentDiv = document.getElementById("content");
        const capturedCanvas = await html2canvas(contentDiv);
        const image = capturedCanvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = image;
        link.download = "captured-image.png";
        link.click();
      },
    },
  ];
  return (
    <div className={styles.btnWrapper}>
      {actions.map((btn) => {
        return (
          <button
            onClick={() => btn.action()}
            key={btn.actionName}
            className={`${styles.commonBtnStyles}`}
          >
            <btn.actionIcon
              className={styles.btnIcon}
              style={{
                fontSize: "40px",
                color:
                  context.showHairstyleInfo &&
                  btn.actionName === "Hairstyle Info"
                    ? "#32a852"
                    : context.flip && btn.actionName === "Flip Hairstyle"
                    ? "#32a852"
                    : context.removeBackground &&
                      btn.actionName === "Remove Background"
                    ? "#32a852"
                    : context.colorSlider &&
                      btn.actionName === "Change Hair Color"
                    ? "#32a852"
                    : "",
              }}
            />
            <span className={styles.btnText}>{btn.actionName}</span>
          </button>
        );
      })}
    </div>
  );
}

export default MyStyleActions;
