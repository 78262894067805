"use client";
import React, { createContext, useState } from "react";
import Packges from "../helper/hairstyles.packages.json";
import Hairstyles from "../helper/hairstyles.hairstylesdetails.json";
import Models from "../helper/hairstyles.models.json";
export const Context = createContext();

function ContextApi({ children }) {
  const freePackgae = Packges.find(
    (elem) => elem._id.$oid === "623aaa2922624503e812c292"
  );
  const [selectedHairstyle, setSelectedHairstyle] = useState(null);
  const [modelSelected, setModelSelected] = useState(null);
  const [clickedImage, setClickedImage] = useState(null);
  const [showHairstyleInfo, setHairstyleInfo] = useState(false);
  const [removeBackground, setRemoveBackground] = useState(false);
  const [colorSlider, setColorSlider] = useState(false);
  const [selectedColor,setSelectedColor] = useState(null)
  const [hairstyleColoredImage,setHairstyleColoredImage] = useState(null)
  const [flip, setFlip] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cameraPhoto, setCameraPhoto] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(freePackgae);
  const [hairstyleDetail, setHairstyleDetail] = useState(
    Hairstyles.find(
      (item) => item._id.$oid === freePackgae?.hairstyles[currentIndex]?.$oid
    )
  );
  const [ModelImg, setModelImg] = useState(
    Models.find(
      (model) =>
        model.category[0].$oid === hairstyleDetail?.category[0]?.$oid &&
        model.status === "active"
    )
  );
  return (
    <Context.Provider
      value={{
        selectedHairstyle,
        setSelectedHairstyle,
        modelSelected,
        setModelSelected,
        clickedImage,
        setClickedImage,
        hairstyleDetail,
        setHairstyleDetail,
        selectedPackage,
        setSelectedPackage,
        currentIndex,
        setCurrentIndex,
        ModelImg,
        setModelImg,
        showHairstyleInfo,
        setHairstyleInfo,
        cameraPhoto,
        setCameraPhoto,
        flip,
        setFlip,
        removeBackground,
        setRemoveBackground,
        colorSlider,
        setColorSlider,
        selectedColor,setSelectedColor,
        hairstyleColoredImage,setHairstyleColoredImage
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default ContextApi;
