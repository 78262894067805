import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import MainHeader from "../MainHeader/MainHeader";
import BottomNavBar from "../BottomNavBar/BottomNavBar";

function ParentLayout({ Component }) {
  return (
    <>
      <Header />
      <MainHeader />
      <Component />
      <BottomNavBar />
      <Footer />
    </>
  );
}

export default ParentLayout;
