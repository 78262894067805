"use client";
import React, { useContext } from "react";
import "./editphoto.css";
import "subjx/dist/style/subjx.css";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useNavigate } from 'react-router-dom';
import { Context } from "../../context/ContextApi";
import { CameraAlt, CheckCircleOutline, RotateLeft, RotateRight, ZoomIn, ZoomOut } from "@mui/icons-material";


function EditPhoto() {
  const navigate = useNavigate();
  const context = useContext(Context);
  const [angle, setAngle] = React.useState(0);
  const rotateImg = (a) => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    cropper.rotate(a - angle);

    setAngle(a);
  };
  const Buttons = [
    {
      text: "Rotate Right",
      icon: RotateRight,
      onClick: () => {
        rotateImg(angle + 1);
        setAngle(angle + 1);
      },
    },
    {
      text: "Rotate Left",
      icon: RotateLeft,
      onClick: () => {
        rotateImg(angle - 1);
        setAngle(angle - 1);
      },
    },
    {
      text: "Zoom Out",
      icon: ZoomOut,
      onClick: () => {
        draw(-0.005);
      },
    },
    {
      text: "Zoom In",
      icon: ZoomIn,
      onClick: () => {
        draw(0.005);
      },
    },
  ];
  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropped=imageElement?.cropper;
  };
  const cropperRef = React.useRef(null);
  function draw(sc) {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    cropper.zoom(sc);
  }
  React.useEffect(() => {
    const allRanges = document.querySelectorAll(".range-wrap");
    allRanges.forEach((wrap) => {
      const range = wrap.querySelector(".range");
      const bubble = wrap.querySelector(".bubble");

      range.addEventListener("input", () => {
        setBubble(range, bubble);
      });
      setBubble(range, bubble);
    });
  }, []);
  function setBubble(range, bubble) {
    const val = range.value;
    const min = range.min ? range.min : -180;
    const max = range.max ? range.max : 180;
    const newVal = Number(((val - min) * 100) / (max - min));
    bubble.innerHTML = val + " degree";
    bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
  }
  const saveImg = async () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const img = cropper
      .getCroppedCanvas({
        width: 640,
        height: 646,
      })
      .toDataURL();
      context.setCameraPhoto(img)
      navigate("/");
  };
  return !context.cameraPhoto?<div className='noCameraPhoto'>
    Tap "Take A Photo" To Select A Photo
  </div>: (
    <div className="parentWrapper">
  <div className="header">
    <p
      style={{
        fontWeight: 500,
        fontSize: "14px",
        margin: "10px auto",
        textAlign: "center",
      }}
    >
      Make any adjustments to fit the blue outlines for the best results.
    </p>
    <p
      style={{
        fontWeight: 300,
        fontSize: "14px",
        margin: "10px auto",
        textAlign: "center",
      }}
    >
      Note: You can reposition the image by dragging the edges or corners of
      the crop rectangle, or move the picture.
    </p>
  </div>

  <div className="edit-photo-instructions">
    <Cropper
      src={context.cameraPhoto}
      style={{ height: "646px", width: "640px" }}
      className="cropper-editor"
      minContainerWidth={100}
      initialAspectRatio={640 / 646}
      guides={true}
      crop={onCrop}
      ref={cropperRef}
      aspectRatio={640 / 646}
      viewMode={0}
      autoCropArea={1}
    />
  </div>

  <div className="edit-photo-actions">
    <span style={{display:"flex"}}>
      {Buttons.map((button) => {
        return (
          <div onClick={button.onClick} className="edit-photo-button">
            <button.icon
              style={{
                cursor: "pointer",
                fontSize: "2.5rem",
                color: "black",
              }}
            ></button.icon>
          </div>
        );
      })}
    </span>

    <button
      className="retake-btn"
      onClick={() => {
        navigate("/show-camera");
        context.setCameraPhoto(null);
      }}
      size="large"
    >
      <CameraAlt style={{ margin: "2px" }} />
      Retake
    </button>
  </div>
  <div className="done-button-div">
    <button
      className="done-btn"
      onClick={() => saveImg()}
      size="large"
    >
      <CheckCircleOutline style={{ color: "white", paddingRight: "5px" }} />
      Done
    </button>
  </div>
</div>

  );
}

export default EditPhoto;
