import React, { useContext } from "react";
import styles from "./modelmodule.module.css";
import { Context } from "../../context/ContextApi";
import Models from "../../helper/hairstyles.models.json";
import { useNavigate } from "react-router-dom";
import { PhotoCamera, PhotoLibrary } from "@mui/icons-material";
function ModelModule() {
  const navigate = useNavigate();
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    getBase64(fileUploaded).then((data) => {
      context.setCameraPhoto(data);
      navigate("/edit-photo");
    });
  };
  const hiddenFileInput = React.useRef(null);
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const Buttons = [
    {
      text: "Take Photo",
      icon: PhotoCamera,
      onClick: () => {
        navigate("/show-camera");
      },
    },
    {
      text: "Upload a photo",
      icon: PhotoLibrary,
      onClick: handleClick,
    },
  ];
  const context = useContext(Context);

  const displayImage = (img) => `data:image/png;base64,${img}`;

  return (
    <div className={styles.parentWrapper}>
      <div className={styles.heading}>click a model to start</div>

      <div className={styles.container}>
        {Models.filter((el) => el.status === "active").map((model) => {
          return (
            <div
              key={model._id.$oid}
              className={styles.modelImg}
              onClick={() => {
                context.setCameraPhoto(displayImage(model.picture));
                navigate("/");
              }}
            >
              <img
                className={styles.hairstyleImg}
                src={displayImage(model.picture)}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.center}>
        <span style={{ fontSize: "40px", fontWeight: 500 }}>OR</span>
      </div>
      <div className={styles.center} style={{width:"40%",margin:"auto"}}>
        {Buttons.map((btn) => {
          return (
            <button
              key={btn.text}
              onClick={btn.onClick}
              className={styles.commonBtnstyles}
            >
              <btn.icon style={{ fontSize: "40px" }} />
              <span className={` ${styles.btnText}`}>{btn.text}</span>
            </button>
          );
        })}
      </div>
      <input
        type="file"
        style={{ display: "none" }}
        ref={hiddenFileInput}
        onChange={handleChange}
      />
    </div>
  );
}

export default ModelModule;
