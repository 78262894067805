"use client";
import React, { useContext } from "react";
import styles from "./hairstyle.module.css";
import Hairstyles from "../../helper/hairstyles.hairstylesdetails.json";
import { Context } from "../../context/ContextApi";
import Models from "../../helper/hairstyles.models.json";
import { useNavigate } from 'react-router-dom';

import Packges from "../../helper/hairstyles.packages.json";

function HairstyleModule({ id }) {
  const navigate = useNavigate();
  const packageSelection = Packges.find(
    (elem) => elem._id.$oid === id
  );
  const context = useContext(Context);
  const updateHairstyleAndModel = async (nextIndex) => {
    const currentHairstyle = context.selectedPackage.hairstyles[nextIndex];
    const newHairstyleDetail = Hairstyles.find(
      (item) => item._id.$oid === currentHairstyle.$oid
    );

    context.setHairstyleDetail(newHairstyleDetail);

    const newModelImg = Models.find(
      (model) =>
        model.category[0].$oid === newHairstyleDetail.category[0].$oid &&
        model.status === "active"
    );

    context.setModelImg(newModelImg);
    if(context.selectedColor ){
      try {
        const response = await fetch(
          `/${newHairstyleDetail?._id?.$oid}_grey.png`
        );;
        const blob = await response.blob();

        const reader = new FileReader();
        reader.onload = () => {
          console.log(document.getElementById("hair"))
          document.getElementById("hair").removeAttribute("data-caman-id");
          window.Caman(`#hair`, function (err) {
            this.revert();
          });
          window.Caman(`#hair`, `/${newHairstyleDetail?._id?.$oid}_grey.png`, function (err) {
            this.revert();
            this.colorize(context.selectedColor.red,context.selectedColor.green,context.selectedColor.blue,context.selectedColor.strength);
            this.render(async function () {
              const picture = this.toBase64();
              context.setHairstyleColoredImage(picture)
            });
          });
         };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    }
  };
  const displayImage = (img) => `data:image/png;base64,${img}`;
  const allHairstyles = id==="6348766baf4a8f29b8b022d6"?Hairstyles:Hairstyles.filter((item) =>
    item.packages.some((obj) => obj.$oid === id)
  );
  const arrayToBeRemoved = []
  packageSelection.hairstyles.forEach(el=>{
    const foundIndex = Hairstyles.findIndex(item=>item._id.$oid === el.$oid)
    if(foundIndex === -1){
      arrayToBeRemoved.push(el.$oid)
    }
  })

  console.log(arrayToBeRemoved,'arrayToBeRemoved')


  const idToIndexMapping = context.selectedPackage.hairstyles.reduce(
    (acc, item, index) => {
      acc[item.$oid] = index;
      return acc;
    },
    {}
  );

  const sortedHairstyles = [...allHairstyles].sort((a, b) => {
    const indexA = idToIndexMapping[a._id.$oid];
    const indexB = idToIndexMapping[b._id.$oid];
    return indexA - indexB;
  });
  return (
    <div className={styles.parentWrapper}>
      <div className={styles.heading}>click a hairstyle to start</div>

      <div className={styles.container}>
        {sortedHairstyles.map((hairstyle, index) => {
          let modelImg = Models.find(
            (model) =>
              model.category[0].$oid === hairstyle.category[0].$oid &&
              model.status === "active"
          );
          return (
            <div
            key={hairstyle._id.$oid}
              className={styles.modelImg}
              onClick={() => {
                context.setSelectedPackage(packageSelection)
                context.setCurrentIndex(index);
                updateHairstyleAndModel(index);
                navigate("/");
                window.scrollTo(0, 0);
              }}
            >
              <img
                className={styles.hairstyleImg}
                src={displayImage(modelImg.picture)}
              />

              <img
                className={styles.hairstyleImg}
                src={`/${hairstyle?._id.$oid}.png`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default HairstyleModule;
