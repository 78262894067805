import React from "react";
import styles from "./mainsidebar.module.css";
import { Edit, LocalMall, PhotoCamera, Portrait } from "@mui/icons-material";
import {useNavigate,useLocation} from 'react-router-dom'

const DrawerMenu = [
  {
    name: "Virtual Hairstyles",
    icon: Portrait,
    to: "/",
  },
  {
    name: "Take a photo",
    icon: PhotoCamera,
    to: "/take-a-photo",
  },
  {
    name: "Edit Photo",
    icon: Edit,
    to: "/edit-photo",
  },
  {
    name: "Hairstyle packages",
    icon: LocalMall,
    to: "/packages",
  },
  // {
  //   name: "Other Apps",
  //   icon: Apps,
  //   to: [Routes.OtherApps.path],
  // },
];

function MainSidebar({ children }) {
  const navigate  = useNavigate();
  const location = useLocation()
  return (
    <div className={styles.sidebarWithChildren}>
      <div className={styles.parentWrapperOfSideBar}>
        <div className={styles.brandImage}>
          <img src="/mainlogo.png" style={{ width: "180px" }} />
        </div>
        <nav >
          <div>
            {DrawerMenu.length > 0 &&
              DrawerMenu.map((item) => {
                return (
                  <div key={item.to} onClick={()=>{
                    navigate(item.to)
                  }}>
                    <div className={`${location.pathname===item.to?styles.selectedNavItem:styles.navItem} py-2`}>
                    <div className={styles.navItemIcon}>
                        <item.icon  style={{fontSize:"40px"}}/>
                        </div>
                    <div className={`${styles.navItemText} `}>
                    {item.name}
                        </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </nav>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}

export default MainSidebar;
