import React from "react";
import styles from "./mainheader.module.css";

function MainHeader() {
  const pagesHeadings = [
    {
      path: "/take-a-photo",
      name: "GET PHOTO",
    },
    {
      path: "/",
      name: "VIRTUAL HAIRSTYLES",
    },
    {
      path: "/edit-photo",
      name: "HAIRSTYLE",
    },
    {
      path: "/packages",
      name: "PACKAGES",
    },
  ];
  function getPageName(path) {
    let name = pagesHeadings.find((el) => el.path === path)?.name;
    return name || "VIRTUAL HAIRSTYLE";
  }
  return (
    <div className={`${styles.headerWrapper}`}>
      {getPageName(window.location.pathname)}
    </div>
  );
}

export default MainHeader;
