import { Routes, Route } from "react-router-dom"
import MyStylePage from "./pages/MyStylePage/MyStylePage";
import Packages from "./pages/PackagesPage/PackagesPage";
import EditPhotoPage from "./pages/EditPhotoPage/EditPhotoPage";
import ShowCamera from "./pages/ShowCamera/ShowCamera";
import Hairstyles from "./pages/HairstylesPage/HairstylesPage";
import ParentLayout from "./components/Parent/Parent"
import TakeAPhoto from "./pages/TakePhotoPage/TakePhotoPage";
import ModelsPage from "./pages/ModelPage/ModelPage";
function App() {
  return (
    <Routes>
        <Route path="/" element={ <ParentLayout Component={MyStylePage}/> } />
        <Route path="/packages" element={ <ParentLayout Component={Packages}/> } />
        <Route path="/edit-photo" element={ <ParentLayout Component={EditPhotoPage}/> } />
        <Route path="/take-a-photo" element={ <ParentLayout Component={TakeAPhoto}/> } />
        <Route path="/show-camera" element={ <ParentLayout Component={ShowCamera}/> } />
        <Route path="/hairstyles/:id" element={ <ParentLayout Component={Hairstyles}/> } />
        <Route path="/models" element={ <ParentLayout Component={ModelsPage}/> } />
      </Routes>
  );
}

export default App;
