import React from "react";
import styles from "./header.module.css";
import { Link } from "react-router-dom";
import Colors from "../../helper/hairstyles.colors.json";
import MobileHeader from "../MobileHeader/MobileHeader";
import { useMediaQuery } from "react-responsive";
function Divider() {
  return <div style={{ borderBottom: "1px solid #f1f1f1" }}></div>;
}
function NavbarDesktop() {
  let arrayOfLinks = [
    {
      name: "women's hairstyle",
      link: "https://hairstylechannel.com/womens-haircuts/",
    },
    {
      name: "men's hairstyle",
      link: "https://hairstylechannel.com/mens-haircuts/",
    },
    {
      name: "stylist",
      link: "https://hairstylechannel.com/stylist/",
    },
    {
      name: "gallery",
      link: "https://hairstylechannel.com/gallery/",
    },
    {
      name: "contributor",
      link: "https://hairstylechannel.com/become-a-contributor/",
    },
    {
      name: "contact us",
      link: "https://hairstylechannel.com/contact/",
    },
    {
      name: "TRY ON HAIRSTYLES",
      link: "https://hairstyleschannel.com/",
    },
  ];

  return (
    <div className={styles.navbarParent}>
      <div className={styles.navbarContentWrapper}>
        <div className={styles.navbarBrand}>
          <Link href="https://hairstylechannel.com/">
            <img
              src="https://hairstylechannel.com/wp-content/themes/hsc/images/hsc.png"
              width="231"
              height="43"
              className="d-inline-block align-top"
              alt="Hairstyle channel"
            />
          </Link>
        </div>

        <div className={styles.navbarMenuLinks}>
          <div className={styles.linksBox}>
            {arrayOfLinks.map((el, index) => {
              return (
                <a
                  key={el.link}
                  href={el.link}
                  className={
                    el.name === "TRY ON HAIRSTYLES"
                      ? styles.Selected
                      : styles.link
                  }
                >
                  {el.name}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

function NavBarMobile() {
  const isMd = useMediaQuery({
    query: "(min-width: 800px)",
  });
  return isMd ? null : <MobileHeader />;
}

function RenderNavBar() {
  return (
    <>
      <NavBarMobile /> <NavbarDesktop />
    </>
  );
}

export default RenderNavBar;
