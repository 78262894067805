"use client";
import React, { useContext, useState } from "react";
import styles from "./mainstyle.module.css";
import { Context } from "../../context/ContextApi";
import { Link, useNavigate } from "react-router-dom";
import MyStyleArrowButtons from "../MyStyleArrowButtons/MyStyleArrowButtons";
import MyStyleActions from "../MyStyleActions/MyStyleActions";
import HairstyleInfo from "../HairstyleInfo/HairstyleInfo";
import ChangeColorSlider from "../ChangeColorSlider/ChangeColorSlider";
import AdComponent from "../AdComponent/AdComponent";

function MainStyle() {
  const HairImage = React.useRef(null);

  const navigate = useNavigate();
  const context = useContext(Context);
  const [showAds, setShowAds] = React.useState(false);

  const displayImage = (img) => `data:image/png;base64,${img}`;

  return (
    <div className={styles.styleWrapper}>
      <div className={styles.headerDetails}>
        {context?.hairstyleDetail?.number && (
          <span
            className={styles.hairstyleNo}
          >{`Hairstyle ${context?.hairstyleDetail?.number}`}</span>
        )}
        <Link to={`/hairstyles/${context.selectedPackage._id.$oid}`}>
          <span className={`${styles.linkToPackageHairstyles}`}>
            see all hairstyles
          </span>
        </Link>
      </div>
      {showAds ? (
        <AdComponent />
      ) : (
        <div className={styles.heroImg} id="content">
          <img
            className={styles.modelAndHairstyleImg}
            src={
              context?.cameraPhoto
                ? context?.cameraPhoto
                : displayImage(context.ModelImg.picture)
            }
          />
          {context.removeBackground && (
            <img className={styles.modelAndHairstyleImg} src={"/Diamong.png"} />
          )}

          <img
            className={styles.modelAndHairstyleImg}
            style={{
              transform: context.flip ? "scaleX(-1) translateX(10px)" : "",
            }}
            src={
              context?.hairstyleColoredImage
                ? context?.hairstyleColoredImage
                : `/${context?.hairstyleDetail?._id.$oid}.png`
            }
          />
          {context.colorSlider && <ChangeColorSlider HairImage={HairImage} />}
        </div>
      )}

      <MyStyleArrowButtons
        showAds={showAds}
        setShowAds={setShowAds}
        HairImage={HairImage}
      />
      <MyStyleActions />
      {context.showHairstyleInfo && (
        <HairstyleInfo hairstyle={context?.hairstyleDetail} />
      )}
      <button
        className={styles.CTAbtn}
        onClick={() => {
          navigate("/take-a-photo");
        }}
      >
        CLICK TO TRY ON 800 HAIRSTYLES TO YOUR OWN PHOTO
      </button>
      <img
        ref={HairImage}
        className="gray-show"
        style={{ height: "0px", width: "0px" }}
        id="hair"
        onChange={() => {
          console.log("changed");
        }}
        src={`/${Image}_grey.png`}
      />
    </div>
  );
}

export default MainStyle;
