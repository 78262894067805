"use client"
import React from "react";
import styles from "./hairstyleinfo.module.css";


function HairstyleInfo({ hairstyle, index }) {
  function replaceCumulative(str, find, replace) {
    for (var i = 0; i < find.length; i++)
      str = str.replace(new RegExp(find[i], "g"), replace[i]);
    return str;
  }
  function extractContent(s) {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }
  const getDescription = (description) => {
    try{

    
    let newArr;
    let subTitle = "";
    newArr =
      description.search("\r\n") !== -1
        ? description?.split("\r\n")
        : description?.split("<br>");

    let arrObj = [];
    if (description) {
      newArr.forEach((el, index) => {
        if (index !== 0) {
          let val = extractContent(el);
          let ObjArr = val.split(":");
          let valKey = replaceCumulative(ObjArr[0], ["&amp;"], ["&"]);

          let NewObj = {
            ...(valKey && ObjArr[1] && { [valKey]: ObjArr[1] }),
          };
          if (Object.keys(NewObj).length !== 0) {
            arrObj.push(NewObj);
          }
        } else {
          subTitle = extractContent(el);
        }
      });
      return (
        <>
          <div
            className={`${styles.smallHairstyleInfoDescription}`}
          >
            {subTitle}
          </div>
          <table className={`${styles.mystyleTable}`}>
            {arrObj.map((el) => {
              const keys = Object.keys(el);
              if (keys?.length > 0) {
                return keys.map((key, index) => {
                  return (
                    <tr 
                    key={key}>
                      <td
                        className={`${styles.mystyleTableTd}`}
                      >
                        {key ? key : ""}:
                      </td>
                      <td
                        className={`${styles.myStyleTableInfo} `}
                      >
                        {el[key] ? el[key] : ""}
                      </td>
                    </tr>
                  );
                });
              } else {
                return null;
              }
            })}
          </table>
        </>
      );
    }
  }catch(e){
    console.log(e)
  }
  };
  return (
    <div 
    style={{background:"white",padding:'10px',margin:'10px'}}>
      <div className={`${styles.hairstyleInfoName} `}>
        {hairstyle?.name}
      </div>
        <div>
          {hairstyle?.description &&
            getDescription(hairstyle?.description)}
        </div>
    </div>
  );
}

export default HairstyleInfo;
