"use client";
import React from "react";
import styles from "./packagemodule.module.css";
import AllPackages from "../../helper/hairstyles.packages.json";
import SinglePackage from "../SinglePackage/SinglePackge";

function PackageModule() {
  return (
    <>
    <div className={styles.packageModuleWrapper}>
      {AllPackages.filter((item) => item.status !== "inactive" && item.title !== "demo").map((item) => {
        return <SinglePackage key={item._id.$oid} item={item} />;
      })}
    </div>
      <div style={{width:"100%",height:"70px"}}>

      </div>
      </>
  );
}

export default PackageModule;
