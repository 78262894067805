import React, { useEffect } from "react";
import styles from "./AdComponent.module.css";

function AdComponent() {
  React.useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);
  return (
    <div className={styles.parentDiv}>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-6841333665916043"
        data-ad-slot="9962565632"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
}

export default AdComponent;
