import { useParams } from 'react-router-dom';
import HairstyleModule from '../../components/HairstyleModule/HairstyleModule'
import MainSidebar from '../../components/MainSidebar/MainSideBar'
import styles from './hairstylespage.module.css'


export default function Hairstyles() {
    let { id } = useParams();
  return (
    <div className={styles.parentWrapper}>
      
      <MainSidebar >
        <HairstyleModule id={id}/>
      </MainSidebar>

    </div>
  )
}
