"use client";
import React from "react";
import styles from './editphoto.module.css'
import MainSidebar from "../../components/MainSidebar/MainSideBar";
import EditPhoto from "../../components/EditPhoto/EditPhoto";

function EditPhotoPage() {
  return (
    <div className={styles.parentWrapper}>
      <MainSidebar>
        <EditPhoto />
      </MainSidebar>
    </div>
  );
}

export default EditPhotoPage;
