"use client";
import React, { useContext } from "react";
import {
  Face,
  PhotoCamera,
  PhotoLibrary,
  RecentActors,
} from "@mui/icons-material";
import styles from "./takeaphoto.module.css";
import { useNavigate } from 'react-router-dom';
import { Context } from "../../context/ContextApi";

function TakeAPhotoModule() {
  const context = useContext(Context)
  const navigate = useNavigate();
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    getBase64(fileUploaded).then((data) => {
      context.setCameraPhoto(data)
              navigate("/edit-photo")
    });
  };
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const Buttons = [
    {
      text: "Take Photo",
      icon: PhotoCamera,
      onClick: () => {
        navigate("/show-camera")
      },
    },
    {
      text: "Upload a photo",
      icon: PhotoLibrary,
      onClick: handleClick,
    },
    // {
    //   text: "Recent Photos",
    //   icon: RecentActors,
    //   onClick: () => {},
    // },
    {
      text: "Use A Model",
      icon: Face,
      onClick: () => {
        navigate("/models")
      },
    },
  ];
  return (
    <div className={styles.parentWrapper}>
      <div className={styles.heading}>
        Choose one of these options to begin trying on different hairstyles.
      </div>
      <div className={styles.btnWrapper}>
        {Buttons.map((btn) => {
          return (
            <button key={btn.text}  onClick={btn.onClick} className={styles.commonBtnstyles}>
              <btn.icon style={{ fontSize: "40px" }} />
              <span className={` ${styles.btnText}`}>
                {btn.text}
              </span>
            </button>
          );
        })}
      </div>

      <div className={styles.guidelinesBox}>
        <div className={`${styles.guidelinesHeading} `}>
        For best results:
        </div>
        <ul className={`${styles.list} `}>
          <li><p>Tie your hair back and behind your ears.</p></li>
           <li> <p>Plain light background.</p></li>
            <li><p>Head and shoulders only.</p></li>
            <li><p>Make sure your eyes are inside the goggles</p>
          </li>
        </ul>

      </div>

      <img src='/old_profile.jpg' className={styles.girlImage}/>
      <input
        type="file"
        style={{ display: "none" }}
        ref={hiddenFileInput}
        onChange={handleChange}
      />
    </div>
  );
}

export default TakeAPhotoModule;
