"use client";
import React from "react";
import styles from './showcamera.module.css'
import MainSidebar from "../../components/MainSidebar/MainSideBar";
import ShowCameraModule from "../../components/ShowCameraModule/ShowCameraModule";

function ShowCamera() {
  return (
    <div className={styles.parentWrapper}>
      <MainSidebar>
        <ShowCameraModule />
      </MainSidebar>
    </div>
  );
}

export default ShowCamera;
