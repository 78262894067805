import React, { useContext } from "react";
import styles from "./changecolorslider.module.css";
import Colors from "../../helper/hairstyles.colors.json";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { Context } from "../../context/ContextApi";
function ChangeColorSlider({HairImage}) {
  const displayImage = (img) => `data:image/png;base64,${img}`;
  const [Image, setImage] = React.useState("");
  const context = useContext(Context);
  const [startIndex, setStartIndex] = React.useState(0);
  const itemsPerPage = 3;

  const showNextImages = () => {
    if (startIndex + itemsPerPage < Colors.length) {
      setStartIndex(startIndex + itemsPerPage);
    }
  };
  const showPreviousImages = () => {
    if (startIndex - itemsPerPage >= 0) {
      setStartIndex(startIndex - itemsPerPage);
    }
  };
  const displayedImages = Colors.slice(startIndex, startIndex + itemsPerPage);

  return (
    <div className={styles.sliderParent}>
     
      <button
        onClick={showPreviousImages}
        disabled={startIndex === 0}
        className={styles.btnArrow}
      >
        <KeyboardDoubleArrowLeftIcon />
      </button>
      <div
        onClick={() => {
          context.setSelectedColor(null);
          context.setColorSlider(false);
          context.setHairstyleColoredImage(null);
          window.Caman(`#${HairImage.current.id}`, function (err) {
            this.revert();
          });
        }}
        className={
          !context.selectedColor ? styles.colorImgActive : styles.colorImg
        }
      >
        
        <img
          src={"/original_color.png"}
          style={{ width: "100%", height: "100%" }}
        />
        <img
          src={"/noselect.png"}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            left: "0px",
          }}
        />
      </div>
      {displayedImages.map((color) => {
        return (
          <div
            key={color._id.$oid}
            className={
              context.selectedColor?._id?.$oid === color._id.$oid
                ? styles.colorImgActive
                : styles.colorImg
            }
            onClick={async () => {

              try {
                const response = await fetch(
                  `/${context?.hairstyleDetail?._id?.$oid}_grey.png`
                );;
                const blob = await response.blob();
        
                const reader = new FileReader();
                reader.onload = () => {
                  console.log(document.getElementById("hair"))
                  document.getElementById("hair").removeAttribute("data-caman-id");
                  window.Caman(`#${HairImage.current.id}`, function (err) {
                    this.revert();
                  });
                  console.log(color,"color")
                  window.Caman(`#hair`, `/${context?.hairstyleDetail?._id?.$oid}_grey.png`, function (err) {
                    this.revert();
                    this.colorize(color.red,color.green,color.blue,color.strength);
                    this.render(async function () {
                      const picture = this.toBase64();
                      context.setHairstyleColoredImage(picture)
                    });
                  });
                 };
                reader.readAsDataURL(blob);
              } catch (error) {
                console.error('Error fetching image:', error);
              }
              // let base64String;
              // // hairstyleColoredImage,setHairstyleColoredImage context?.hairstyleDetail?._id.$oid
              // let greScale = await fetch(
              //   `/${context?.hairstyleDetail?._id?.$oid}_grey.png`
              // );
              // let base64Grey = await greScale.blob();
              // const reader = new FileReader();
              // reader.onload = () => {
              //   console.log(reader.result)
              //   
              // };
              context.setSelectedColor(color);
              context.setColorSlider(false);
            }}
          >
            <img
              src={color.picture}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        );
      })}
      <button
        onClick={showNextImages}
        disabled={startIndex + itemsPerPage >= Colors.length}
        className={styles.btnArrow}
      >
        <KeyboardDoubleArrowRightIcon />
      </button>
    </div>
  );
}

export default ChangeColorSlider;
