import React, { useContext } from "react";
import styles from "./singlepackage.module.css";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/ContextApi";

function SinglePackage({ item }) {
  const navigate = useNavigate();
  const context = useContext(Context);
  const displayImage = (img) => `data:image/png;base64,${img}`;
  return (
    <div className={styles.packageWrapper}>
      <img src={displayImage(item.picture)} width="200" />
      <div className={styles.packageTitle}>{item.title}</div>
      <div className={styles.packageDescription}>{item.description}</div>
      <div className={styles.noOfHairstyles}>
        No of Hairstyles {item.hairstyles.length}
      </div>
      <button
        className={styles.previewNowBtnGreen}
        onClick={() => {
          console.log(item._id.$oid);
          context.setSelectedPackage(item);
          context.setCurrentIndex(0);
          navigate(`/hairstyles/${item._id.$oid}`);
          window.scrollTo(0, 0);
        }}
      >
        Try Now
      </button>
      {/* <button className={styles.buyNowOrTryNowBtn}>Try Now</button> */}
    </div>
  );
}

export default SinglePackage;
