import React from "react";
import styles from "../Header/header.module.css";
import {Link} from "react-router-dom";

function Footer() {
  let linkArr = [
    {
      name: "women's hairstyles",
      link: "https://hairstylechannel.com/womens-haircuts/",
    },
    {
      name: "men's hairstyles",
      link: "https://hairstylechannel.com/mens-haircuts/",
    },
    {
      name: "gallery",
      link: "https://hairstylechannel.com/gallery/",
    },
    {
      name: "stylist",
      link: "https://hairstylechannel.com/stylist/",
    },
    {
      name: "Become a Contributor",
      link: "https://hairstylechannel.com/become-a-contributor/",
    },
    {
      name: "Promote Your Work",
      link: "https://hairstylechannel.com/promote-your-work/",
    },
    {
      name: "Try On Hairstyles",
      link: "https://hairstyleschannel.com/",
    },
  ];

  return (
    <div className={styles.footer}>
      <div className={styles.parent}>
        <div className={styles.firstBox}>
          <p className={styles.headingFooter}>Hairstyle Channel</p>
          <p className={styles.headingDescription}>
            Hairstyle channel believes that progress equals happiness, that we
            all need some variety and change in our lives in order to move
            forward, grow and feel great.
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            <Link
            target="_blank"
              style={{
                borderRadius: "10px",
                marginRight: "10px",
              }}
              to="https://www.instagram.com/officalhairstylechannel/?hl=en"
            >
              <span>
                <img
                  src="https://hairstylechannel.com/wp-content/uploads/2022/05/instagram-footer.png"
                  alt="instagram"
                />
              </span>
            </Link>
            <Link
            target="_blank"
              style={{
                borderRadius: "10px",
              }}
              to="https://www.facebook.com/AllHairstylesChannel/"
            >
              <span>
                <img
                  src="https://hairstylechannel.com/wp-content/uploads/2022/05/facebook-footer.png"
                  alt="facebook"
                />
              </span>
            </Link>
          </div>
        </div>
        <div className={styles.secondBox}>
          <p className={styles.headingFooterSecondBox}>page links</p>
          {linkArr.map((el) => {
            return (
              <Link
              key={el.link}
                className={styles.smallLinks}
                style={{
                  paddingBottom: "8px",
                }}
                to={el.link}
              >
                {el.name}
              </Link>
            );
          })}
        </div>
        <div className={styles.thirdBox}>
          <p className={styles.headingFooterSecondBox}>need help</p>
          <Link
            className={styles.smallLinks}
            style={{
              paddingBottom: "8px",
              marginBottom: "15px",
            }}
            to="https://hairstylechannel.com/contact/"
          >
            Contact us
          </Link>
          <p className={styles.headingFooterSecondBox}>get in touch</p>
          <div>
          
          <Link
            className={styles.smallLinks}
            style={{
              paddingBottom: "8px",
            }}
            to="tel: (02) 7252 3399"
            >
            (02) 7252 3399
          </Link>
            </div>
          <Link
            className={styles.smallLinks}
            style={{
              paddingBottom: "8px",
            }}
            to="mailto:Support@hairstylechannel.com"
          >
            Support@hairstylechannel.com
          </Link>
        </div>
      </div>
      <div className={styles.belowMainFooter}>
        <div style={{ paddingLeft: "15px", display: "flex" }}>
          <Link
            className={styles.belowFooterLinks}
            to="https://hairstylechannel.com/sitemap/"
          >
            sitemap
          </Link>
          <Link
            className={styles.belowFooterLinks}
            to="https://hairstylechannel.com/terms-conditions/"
          >
            terms & conditions
          </Link>
          <Link
            className={styles.belowFooterLinks}
            to="https://hairstylechannel.com/privacy-policy/"
          >
            privacy policy
          </Link>
        </div>
      </div>
      <div style={{ borderBottom: "1px solid rgb(191,191,191)" }}></div>
      <div className={styles.bottomMostTitle}>
        <div className={styles.titleDate}>
          © {new Date().getFullYear()} Hairstylechannel All Rights Reserved
        </div>
        <div className={styles.titleDate}>
          Designed and Developed by 
          <img
            src="https://hairstylechannel.com/wp-content/themes/hsc/images/why-digital.png"
            width="71"
            height="20"
            style={{marginLeft:"10px"}}
          />
        </div>
      </div>
    </div>
  );
}

export default Footer;

// https://hairstylechannel.com/wp-content/themes/hsc/images/why-digital.png
