import React from "react";
import styles from "./bottomnavbar.module.css";
import { Edit, LocalMall, PhotoCamera, Portrait } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const DrawerMenu = [
  {
    name: "Virtual Hairstyles",
    icon: Portrait,
    to: "/",
  },
  {
    name: "Take a photo",
    icon: PhotoCamera,
    to: "/take-a-photo",
  },
  {
    name: "Edit Photo",
    icon: Edit,
    to: "/edit-photo",
  },
  {
    name: "Hairstyle packages",
    icon: LocalMall,
    to: "/packages",
  },
  // {
  //   name: "Other Apps",
  //   icon: Apps,
  //   to: [Routes.OtherApps.path],
  // },
];
function BottomNavBar() {
  const isNotMd = useMediaQuery({
    query: "(min-width: 800px)",
  });
  const navigate = useNavigate();
  const location = useLocation();
  return isNotMd ? null : (
    <div className={styles.Parent}>
      {DrawerMenu.map((item) => {
        return (
          <div
            className={
              location.pathname === item.to
                ? styles.BottomNavItemSelected
                : styles.BottomNavItem
            }
            key={item.to}
            onClick={() => {
              navigate(item.to);
            }}
          >
            <item.icon style={{ fontSize: "20px" }} />
            <div className={styles.bottomNavItemText}>{item.name}</div>
          </div>
        );
      })}
    </div>
  );
}

export default BottomNavBar;
