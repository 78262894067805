import MainSidebar from '../../components/MainSidebar/MainSideBar'
import ModelModule from '../../components/ModelModule/ModelModule'
import styles from './modelpage.module.css'


export default function ModelsPage() {
  return (
    <div className={styles.parentWrapper}>
      
      <MainSidebar >
        <ModelModule />
      </MainSidebar>

    </div>
  )
}
