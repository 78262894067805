
import MainSidebar from '../../components/MainSidebar/MainSideBar'
import MainStyle from '../../components/MainStyle/MainStyle'
import styles from './mystyle.module.css'

export default function MyStylePage() {
  return (
    <div className={styles.parentWrapper}>
      
      <MainSidebar >
        <MainStyle />
      </MainSidebar>

    </div>
  )
}
